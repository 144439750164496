import { storeToRefs } from "pinia";

import { useSystem } from "@/composables/system";
import { useForm } from "@/composables/form";

import { useUserStore } from "@/stores/user";

/**
 * Compsable para funções de usuário.
 */
function useUser() {
  const API_URL = useSystem().API_URL;

  const userStore = useUserStore();

  /**
   * Função responsável pela recuperação de senha.
   */
  async function recover(email, type, errors, fieldset, message, formType) {
    return await useForm(
      `${API_URL}/account/password`,
      {
        email: email,
        type: type
      },
      (response) => {
        const data = response.data;
        message.value = data.message;
        formType.value = "login";
      },
      (error) => {
        if (error.response) {
          if ("errors" in error.response.data) {
            errors.value = error.response.data.errors;
          } else {
            const message = `
            Houve um problema com a solicitação.
            Por favor, tente novamente mais tarde.
            `;
            errors.value = [message];
          }
        }
      },
      () => {
        fieldset.value.disabled = false;
      },
      "PUT"
    );
  }

  /**
   * Redefine a senha do usuário.
   *
   * @param {*} data
   * @param {*} fieldset
   * @param {*} message
   */
  async function reset(data, fieldset, message, success, error, done) {
    if (data.password === data.passwordConfirmation) {
      const { storedID, storedToken } = storeToRefs(userStore);

      return await useForm(
        `${API_URL}/users/${storedID.value}/password`,
        {
          api_token: storedToken.value,
          password: data.password
        },
        success,
        error,
        done,
        "PUT"
      );
    }

    fieldset.value.disabled = false;
    message.value = "A nova senha e sua confirmação devem ser iguais.";
  }

  return { recover, reset };
}

export { useUser };
