/**
 * Composable para dados genéricos referentes à aplicação.
 *
 * @returns {object} Os dados da aplicação.
 */
function useSystem() {
  const APP_URL = process.env.VUE_APP_BASE_URL;
  const API_URL = process.env.VUE_APP_API_URL;

  return { APP_URL, API_URL };
}

export { useSystem };
