import { ref } from "vue";

import axios from "axios";

/**
 * Composable de abstração para as chamadas de requisições.
 *
 * @callback success
 * @callback error
 * @callback complete
 *
 * @param {object} options  Opções de inicialização.
 * @param {success} success Callback para caso de sucesso.
 * @param {error} error     Callback para caso de erro.
 * @param {complete} done   Callback para o fim da requisição.
 *
 * @returns {Object} A resposta da requisição.
 */
async function useAjax(options, success = null, error = null, done = null) {
  const data = ref(null);
  // Define o callback de sucesso.
  success = success
    ? success
    : (response) => {
        data.value = response.data;
      };
  await axios(options).then(success).catch(error).then(done);

  return data;
}

export { useAjax };
