<template>
  <section class="container-login">
    <div class="login-content">
      <pop-up v-model="message" v-if="message" />
      <form
        v-if="formType === 'login'"
        @submit.prevent="login"
        class="form-login"
      >
        <fieldset ref="fieldset">
          <div class="login">
            <a href="#" class="img-logo">
              <img src="@/assets/imgs/logologin.png" alt="Logo" />
            </a>
            <h2 class="titulo-login">Carneiros Temporada</h2>
            <h3 class="titulo-login">Login</h3>
            <!-- <div class="warning">
              <p>
                Atualmente a área do proprietário encontra-se em manutenção.
              </p>
            </div> -->
            <div v-if="errors.length > 0" class="class-tratamento">
              <ul>
                <li v-for="(error, index) in errors" :key="index">
                  &bullet; {{ error }}
                </li>
              </ul>
            </div>
            <div class="labels-button">
              <input
                class="login"
                type="email"
                v-model.trim="email"
                placeholder="E-mail"
                required
              />
              <input
                class="login"
                type="password"
                v-model="password"
                placeholder="Senha"
                required
              />
              <div class="container-cargos">
                <div class="proprietario">
                  <input type="radio" v-model="type" id="owner" value="owner" />
                  <label for="owner">Proprietário</label>
                </div>
                <div class="proprietario">
                  <input
                    type="radio"
                    v-model="type"
                    id="operator"
                    value="operator"
                  />
                  <label for="operator">Operador</label>
                </div>
              </div>
              <div class="buttons">
                <button
                  type="button"
                  @click="() => (formType = 'recover')"
                  class="alternative-button"
                >
                  Recuperar Senha
                </button>
                <button type="submit">Entrar</button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <form
        v-else-if="formType === 'recover'"
        @submit.prevent="recover"
        class="form-login"
      >
        <fieldset ref="fieldset">
          <div class="login">
            <a href="#" class="img-logo">
              <img src="@/assets/imgs/logologin.png" alt="Logo" />
            </a>
            <h2 class="titulo-login">Carneiros Temporada</h2>
            <h3 class="titulo-login">Recuperação de Senha</h3>
            <div v-if="errors.length > 0" class="class-tratamento">
              <ul>
                <li v-for="(error, index) in errors" :key="index">
                  &bullet; {{ error }}
                </li>
              </ul>
            </div>
            <div class="labels-button">
              <input
                class="login"
                type="email"
                v-model.trim="email"
                placeholder="E-mail"
                required
              />
              <div class="container-cargos">
                <div class="proprietario">
                  <input type="radio" v-model="type" id="owner" value="owner" />
                  <label for="owner">Proprietário</label>
                </div>
                <div class="proprietario">
                  <input
                    type="radio"
                    v-model="type"
                    id="operator"
                    value="operator"
                  />
                  <label for="operator">Operador</label>
                </div>
              </div>
              <div class="buttons">
                <button
                  type="button"
                  @click="() => (formType = 'login')"
                  class="alternative-button"
                >
                  Fazer Login
                </button>
                <button type="submit">Recuperar</button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref } from "vue";

import { useAuth } from "@/composables/auth";
import { useUser } from "@/composables/user";

import PopUp from "@/components/popup/PopUp.vue";

export default defineComponent({
  components: {
    PopUp
  },
  setup() {
    // Os dados do formulário.
    const email = ref("");
    const password = ref("");
    const type = ref("owner");

    const formType = ref("login");

    const message = ref("");
    const errors = ref([]);

    const fieldset = ref(null);

    const access = useAuth().access;
    const recoverPassword = useUser().recover;

    const login = async () => {
      // Limpa os erros anteriores.
      fieldset.value.disabled = true;
      errors.value = [];
      await access(email.value, password.value, type.value, errors, fieldset);
    };

    const recover = async () => {
      // Limpa os erros anteriores.
      fieldset.value.disabled = true;
      errors.value = [];
      await recoverPassword(
        email.value,
        type.value,
        errors,
        fieldset,
        message,
        formType
      );
    };

    return {
      login,
      recover,
      email,
      password,
      type,
      errors,
      fieldset,
      message,
      formType
    };
  }
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables";
.container-login {
  background-image: linear-gradient(-90deg, $degrade1, $degrade2);
  height: 45vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  .class-tratamento {
    width: 80%;

    ul {
      color: red;
      list-style-type: disc !important;
    }
  }
  .login-content {
    position: absolute;
    top: 35%;
    width: 510px;
    border-radius: 25px;
    background: #fff;
    border: 1px solid $cinza;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
    padding: 1rem 0 6rem 0;
  }
  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .img-logo {
      width: 159px;
      height: 150px;

      img {
        width: 100%;
        border: 1px solid $cinza;
        border-radius: 50%;
      }
    }

    h2 {
      font-size: 1.5rem;
      color: $cor-principal;
      font-weight: unset;
      margin-top: 1rem;
      margin-bottom: 2rem;
      text-align: center;
    }

    h3 {
      margin-bottom: 1rem;
    }
  }

  .labels-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2rem;
    margin-top: 3rem;
    width: 80%;
    input {
      font-family: Arial, Helvetica, sans-serif;
    }
    .container-cargos {
      display: flex;
      width: 100%;
      .proprietario {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: 100%;
      }
    }
    .login {
      border: none;
      padding: 0 0 5px 10px;
      border-bottom: 2px solid $cinza;
      width: 100%;
      font-size: $tamanho-fonte;
      transition: ease-in 0.3s;
    }

    .buttons {
      display: flex;
      width: 100%;
      gap: 1rem;

      button {
        border: none;
        color: #000;
        background: $verde;
        padding: 0.5rem 2.5rem 0.5rem 2.5rem;
        border-radius: 19px;
        font-size: $tamanho-fonte;
        cursor: pointer;
        transition: all ease 0.3s;
        &:hover {
          color: #fff;
          background: $cor-principal;
        }
      }

      .alternative-button {
        background: transparent;
      }
    }
  }
}

.warning {
  background: rgb(230, 210, 210);
  border: 1px solid rgb(170, 48, 48);
  color: rgb(170, 48, 48);
  margin: 1rem 2rem;
  padding: 1rem;
  text-align: center;
}

@media (max-width: 576px) {
  .container-login {
    .login-content {
      width: 90%;
    }

    .labels-button {
      align-items: center;

      .buttons {
        flex-direction: column-reverse;
      }
    }
  }
}
</style>
