import { storeToRefs } from "pinia";

import { useSystem } from "@/composables/system";
import { useAjax } from "@/composables/ajax";

import { useUserStore } from "@/stores/user";

/**
 * Composable para submissão de formulários.
 *
 * @callback success
 * @callback error
 * @callback complete
 *
 * @param {string} url      URL da requisição.
 * @param {object} data     Dados da requisição.
 * @param {success} success Callback para caso de sucesso.
 * @param {error} error     Callback para caso de erro.
 * @param {complete} done   Callback para o fim da requisição.
 * @param {string} method   Método da requisição.
 */
async function useForm(
  url,
  data = {},
  success = null,
  error = null,
  done = null,
  method = "POST"
) {
  const { token } = storeToRefs(useUserStore());

  return await useAjax(
    {
      url: url,
      method: method,
      data: data,
      headers: {
        Authorization: `Bearer ${token.value}`
      }
    },
    success,
    error,
    done
  );
}

/**
 * Funções relacionadas a tratamento de inputs.
 *
 * @returns
 */
function useInput() {
  const BASE = useSystem().API_URL;
  const { token } = storeToRefs(useUserStore());

  const loadImage = (event, index, images, data) => {
    const file = event.target.files[0];
    if (data.images[index]) {
      data.images[index]["file"] = file;
    } else {
      data.images[index] = { file };
    }
    images[index] = URL.createObjectURL(file);
  };

  const loadMultipleFiles = (event, images, data) => {
    const files = event.target.files;
    for (let file of files) {
      images.push({
        name: file.name,
        path: URL.createObjectURL(file)
      });
      data.images.push({ file: file });
    }
  };

  const removeFile = (index, images, data) => {
    images.splice(index, 1);
    data.images.splice(index, 1);
  };

  const loadMultipleFilesAuto = (event, resource) => {
    const data = new FormData();
    let file = null;
    for (file of event.target.files) {
      data.append("images[]", file);
    }

    const url = `${BASE}/${resource}?api_token=${token.value}`;

    return useForm(url, data);
  };

  const removeFileAuto = (resource) => {
    const url = `${BASE}/${resource}?api_token=${token.value}`;

    return useAjax({
      url,
      method: "DELETE"
    });
  };

  return {
    loadImage,
    loadMultipleFiles,
    removeFile,
    loadMultipleFilesAuto,
    removeFileAuto
  };
}

export { useForm, useInput };
