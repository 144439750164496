<template>
  <div class="container-popup">
    <div class="botao-sair">
      <button type="button" @click="close">&times;</button>
    </div>
    <div class="erros">
      <p>{{ modelValue }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const close = () => {
      emit("update:modelValue", "");
    };

    return {
      close
    };
  }
});
</script>

<style lang="scss">
.container-popup {
  .botao-sair {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: right;
  }

  background: #ffffff;
  border-radius: 10px;
  bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  height: 30vh;
  left: 0;
  margin: auto;
  padding: 1rem;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  width: 400px;
  z-index: 100;
}
</style>
