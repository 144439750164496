import { defineStore } from "pinia";

/**
 * Store para os dados do usuário.
 */
export const useUserStore = defineStore("user", {
  state: () => {
    return {
      type: "",
      token: "",
      id: "",
      name: "",
      title: "",
      photo: ""
    };
  },

  getters: {
    /**
     * Resgata o token de autenticação atual.
     *
     * @param {Object} state    O estado atual.
     *
     * @returns {string} O token de autenticação.
     */
    storedToken: (state) => {
      if (!state.token) {
        // Se não houver um token definido, busca por um persistido.
        state.token = localStorage.getItem("auth-token");
      }

      return state.token;
    },

    /**
     * Resgata o tipo de autenticação atual.
     *
     * @param {Object} state    O estado atual.
     *
     * @returns {string} O tipo de autenticação.
     */
    storedType: (state) => {
      if (!state.type) {
        // Se não houver um tipo definido, busca por um persistido.
        state.type = localStorage.getItem("auth-type");
      }

      return state.type;
    },

    /**
     * Resgata o ID do usuário.
     *
     * @param {Object} state    O estado atual.
     *
     * @returns {string} O ID do usuário.
     */
    storedID: (state) => {
      return state.id;
    },

    /**
     * Checa se o usuário está autenticado.
     *
     * @param {Object} store
     *
     * @returns {boolean} Verdadeiro se está autenticado. Caso contrário,
     * falso.
     */
    checkAuthenticated: (store) => {
      return (type) =>
        store.storedToken && store.storedType === type ? true : false;
    },

    /**
     * Checa se existe um token armazenado.
     *
     * @returns {boolean} Verdadeiro se existe token. Caso contrário, falso.
     */
    hasToken: (store) => {
      return store.storedToken ? true : false;
    }
  },

  actions: {
    /**
     * Atualiza os dados de autenticação e persiste no local storage.
     *
     * @param {string} token    Token de autenticação.
     * @param {string} type     Tipo de usuário.
     */
    registerAuth(token, type) {
      this.token = token;
      this.type = type;

      this.updateToken(token);
      localStorage.setItem("auth-type", this.type);
    },

    /**
     * Destrói a sessão atual do usuário.
     */
    removeAuth() {
      localStorage.removeItem("auth-token");
      localStorage.removeItem("auth-type");

      this.$reset();
    },

    /**
     * Atualiza os dados do usuário.
     *
     * @param {string} id     ID do usuário.
     * @param {string} name   Nome do usuário.
     * @param {string} title  Nome do usuário customizado.
     * @param {string} photo  Foto de perfil.
     */
    registerUser(id, name, title = "", photo = "") {
      this.id = id;
      this.name = name;
      this.title = title;
      this.photo = photo;
    },

    /**
     * Atualiza o token.
     *
     * @param {*} token
     */
    updateToken(token) {
      this.token = token;
      localStorage.setItem("auth-token", this.token);
    }
  }
});
