import { createRouter, createWebHistory } from "vue-router";

import LoginView from "@/views/LoginView.vue";

import { useRouting } from "@/composables/routing";

const routing = useRouting();

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginView,
    beforeEnter: routing.redirect
  },
  {
    path: "/owner/password",
    name: "AlterarSenhaOwner",
    component: () => import("@/views/shared_components/ChangePassword.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "owner"
    }
  },
  {
    path: "/immobiles",
    name: "HomeOwner",
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("@/views/owner/HomeOwnerView.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "owner"
    }
  },
  {
    path: "/immobiles/:id",
    name: "ReportOwner",
    component: () => import("@/views/owner/ReportOwnerView.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "owner"
    }
  },
  {
    path: "/operator/password",
    name: "AlterarSenhaOperator",
    component: () => import("@/views/shared_components/ChangePassword.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey",
    name: "SurveyOperator",
    component: () =>
      import(/* Survey home */ "@/views/survey/HomeSurveyView.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey/activity",
    name: "AcivitySurvey",
    component: () =>
      import(/* Survey home */ "@/views/survey/AcivitySurvey.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey/checklist",
    name: "SurveyChecklistActivity",
    component: () =>
      import(/* Survey Checklist */ "@/views/survey/ChecklistActivity.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey/activity/complete",
    name: "AcivityComplete",
    component: () =>
      import(/* Survey home */ "@/views/survey/CompleteActivity.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey/items",
    name: "InventoryImmobiles",
    component: () =>
      import(/* Survey home */ "@/views/survey/ImmobilesView.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey/items/:immobile",
    name: "Inventory",
    component: () =>
      import(/* Survey home */ "@/views/survey/InventoryHome.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey/items/:immobile/environments/:environment",
    name: "InventoryItems",
    component: () =>
      import(/* Survey home */ "@/views/survey/InventoryItems.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey/items/:immobile/environments/:environment/relations/:item",
    name: "RegisterItems",
    component: () =>
      import(/* Survey home */ "@/views/survey/RegisterItems.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey/items/:immobile/environments/:environment/relations/:item/photos",
    name: "InventoryPhotos",
    component: () =>
      import(/* Survey home */ "@/views/survey/InventoryPhotos.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey/occurrences",
    name: "OccurrenceImmobile",
    component: () =>
      import(/* Survey home */ "@/views/survey/OccurrenceImmobile.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey/occurrences/:immobile",
    name: "CorrectiveMaintenanceList",
    component: () =>
      import(/* Survey home */ "@/views/survey/CorrectiveMaintenanceList.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey/occurrences/:immobile/details/:occurrence",
    name: "CorrectiveMaintenance",
    component: () =>
      import(/* Survey home */ "@/views/survey/CorrectiveMaintenance.vue"),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  },
  {
    path: "/survey/occurrences/:immobile/details/:occurrence/photos",
    name: "CorrectiveMaintenancePhotos",
    component: () =>
      import(
        /* Survey home */ "@/views/survey/CorrectiveMaintenancePhotos.vue"
      ),
    meta: {
      requiresAuth: true,
      typeUser: "operator"
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Adiciona a proteção das páginas.
router.beforeEach(routing.protect);

export default router;
