import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import Maska from "maska";

// Global styling.
import "./assets/styles/main.scss";

createApp(App).use(createPinia()).use(router).use(Maska).mount("#app");
