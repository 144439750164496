import { ref } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

import { useUserStore } from "@/stores/user";

import { useSystem } from "@/composables/system";
import { useAjax } from "@/composables/ajax";
import { useForm } from "@/composables/form";

/**
 * Compsable para funções de autenticação do usuário.
 */
function useAuth() {
  const router = useRouter();
  const userStore = useUserStore();

  /**
   * Função responsável pela autenticação do usuário.
   */
  async function access(email, password, type, errors, fieldset) {
    return await useForm(
      `${useSystem().API_URL}/auth`,
      {
        email: email,
        password: password,
        type: type
      },
      (response) => {
        // Em caso de sucesso no login, registra o token e redireciona.
        const data = response.data;
        const userStore = useUserStore();
        const typeUser = type.toLowerCase();
        const target = typeUser == "owner" ? "HomeOwner" : "SurveyOperator";

        // Persiste o token de autenticação, o tipo e dados do usuário.
        userStore.registerAuth(data.token, typeUser);

        // Redireciona o usuário para a respectiva página.
        router.push({
          name: target
        });
      },
      (error) => {
        if (error.response) {
          if ("errors" in error.response.data) {
            errors.value = error.response.data.errors;
          } else {
            const message = `
            Houve um problema com a solicitação.
            Por favor, tente novamente mais tarde.
            `;
            errors.value = [message];
          }
        }
      },
      () => {
        fieldset.value.disabled = false;
      }
    );
  }

  // Função responsável pelo fim da sessão do usuário.
  const logout = () => {
    userStore.removeAuth();
    router.push({
      name: "Login"
    });
  };

  // Valida a autenticação do usuário.
  const validate = async (type) => {
    const validation = ref(false);

    // Checa se existe dados locais da autenticação.
    if (userStore.checkAuthenticated(type)) {
      const { storedToken } = storeToRefs(userStore);
      // Checa se os dados locais são válidos na API.
      await useAjax(
        {
          url: `${useSystem().API_URL}/auth?api_token=${storedToken.value}`
        },
        (response) => {
          validation.value = response.data;
        },
        () => {
          validation.value = false;
        }
      );
    }

    return validation.value;
  };

  return {
    access,
    logout,
    validate
  };
}

export { useAuth };
