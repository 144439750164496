import { storeToRefs } from "pinia";

import { useAuth } from "@/composables/auth";

import { useUserStore } from "@/stores/user";

/**
 * Composable para rotas.
 */
function useRouting() {
  // Redireciona para a home adequada, caso esteja autenticado.
  const redirect = async () => {
    const userStore = useUserStore();
    const { storedType } = storeToRefs(userStore);

    if (userStore.hasToken) {
      if (await useAuth().validate(storedType.value)) {
        const target =
          storedType.value == "owner" ? "HomeOwner" : "SurveyOperator";

        return {
          name: target
        };
      }
    }
  };

  // Adiciona a proteção das páginas.
  const protect = async (to) => {
    const userStore = useUserStore();

    const requiresAuth = to.meta.requiresAuth;
    const type = to.meta.typeUser;

    const data = await useAuth().validate(type);
    if (requiresAuth && !data) {
      return {
        path: "/"
      };
    } else {
      const title =
        typeof data == "object" && "title" in data ? data.title : "";
      const photo =
        typeof data == "object" && "photo" in data ? data.photo : "";
      userStore.registerUser(data.id, data.name, title, photo);
    }
  };

  return {
    redirect,
    protect
  };
}

export { useRouting };
